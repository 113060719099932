



































































































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { getStatusColor } from '@/utils';
import { PokerSession, PokerStory, PokerStories } from '../models';
import { pokerService } from '../services';
import ThemeSelector from './ThemeSelector.vue';

@Component({ components: { ThemeSelector } })
export default class SessionBuilder extends Vue {
  @PropSync('value', { type: Object as () => PokerSession, required: true })
  session!: PokerSession;

  getStatusColor = getStatusColor;
  config: any = {};
  stories: PokerStories = [];
  showStories = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get sessionExists(): boolean {
    return this.session.createdOn !== undefined;
  }

  @Watch('session.title')
  onTitleChange(title) {
    document.title = title;
  }

  @Watch('session.stories')
  onSessionStoriesChange(stories) {
    if (stories) {
      this.stories = stories.map((s) => s.title).join('\n');
      this.showStories = stories.length > 0;
    }
  }

  @Watch('stories')
  onLocalStoriesChange(stories) {
    this.session.stories = [];
    stories.split('\n').forEach((s) => {
      if (!s) return;
      const story: PokerStory = { title: s };
      this.session.stories.push(story);
    });
  }

  created() {
    this.$bind('config', pokerService.getConfigRef());
  }

  saveSession() {
    this.$emit('save', this.session);
  }
  deleteSession() {
    this.$emit('delete', this.session);
  }

  setSessionStatus(status: string) {
    this.session.status = status;
    if (status === 'Active') {
      this.session.completedOn = null;
      this.session.startedOn = Date.now();
    } else if (status === 'Closed') {
      this.session.completedOn = Date.now();
    }
    this.saveSession();
  }
}
