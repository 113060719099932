
import { Vue, Component, Prop } from 'vue-property-decorator';
import { PokerSessions } from '../models';
import { pokerService } from '../services';
import SessionsList from './SessionsList.vue';

@Component({ components: { SessionsList } })
export default class SessionsPanel extends Vue {
  draftSessions: PokerSessions = [];
  activeSessions: PokerSessions = [];
  closedSessions: PokerSessions = [];

  loading = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  async load() {
    // this.$bus.$emit('loading-indicator', true);
    this.loading = true;
    await Promise.all([
      this.$bind('draftSessions', pokerService.getDraftSessions(this.userId).orderBy('createdOn', 'desc')),
      this.$bind('activeSessions', pokerService.getActiveSessions(this.userId).orderBy('createdOn', 'desc')),
      this.$bind('closedSessions', pokerService.getClosedSessions(this.userId).orderBy('createdOn', 'desc')),
    ]);
    this.loading = false;
    // this.$bus.$emit('loading-indicator', false);
  }

  created() {
    this.load();
  }
}
