
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { Participant, Participants as ParticipantsType } from '@/models';

@Component
export default class Participants extends Vue {
  @Prop({ type: Array as () => ParticipantsType, required: true })
  readonly value!: ParticipantsType;

  @Prop({ default: null })
  readonly selected!: string;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly indicator!: boolean;

  formatDate = formatDate;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get participants(): ParticipantsType {
    //return this.value.sort((a, b) => a.username!.localeCompare(b.username!));
    return this.value;
  }

  getColor(participantId: string): string {
    let color = 'white';
    if (this.selected && participantId === this.selected) {
      color = 'secondary';
    } else if (this.value.find((p) => p.id == participantId && p.active)) {
      color = 'green';
    } else if (participantId === this.userId) {
      color = 'primary';
    }
    return color;
  }
  selectParticipant(participant: Participant) {
    if (this.readonly) return;
    if (this.selected === participant.id) {
      // same person clicked -> deselect
      this.$emit('select', null);
    } else {
      this.$emit('select', participant);
    }
  }
}
