
import { Vue, Component } from 'vue-property-decorator';
import { downloadJson } from '@/utils';
import { PokerSession, User } from '../models';
import { pokerService } from '../services';
import SessionsPanel from '../components/SessionsPanel.vue';

@Component({ components: { SessionsPanel } })
export default class Dashboard extends Vue {
  showImportDialog = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['user/viewModeIcon'];
  }

  created() {
    // Event listeners
    this.$bus.$off('session-clone').$on('session-clone', this.onSessionClone);
    this.$bus.$off('session-export').$on('session-export', this.onSessionExport);
    this.$bus.$off('session-delete').$on('session-delete', this.onSessionDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Poker', '/poker');
    document.title = 'AgileMate Poker';
    this.$analytics.logEvent('poker-dashboard');
  }

  // Event handlers
  async onSessionClone(session: PokerSession) {
    try {
      await pokerService.cloneSession(session, this.user);
      this.$bus.$emit('snackbar-notify', 'Session successfully cloned', 'success');
      this.$analytics.logEvent('poker-clone', { session_id: session.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSessionExport(session: PokerSession) {
    try {
      downloadJson(session, session.id);
      this.$bus.$emit('snackbar-notify', 'Session successfully exported', 'success');
      this.$analytics.logEvent('poker-export', { session_id: session.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSessionDelete(session: PokerSession) {
    try {
      await pokerService.deleteSession(session.id);
      this.$bus.$emit('snackbar-notify', 'Session successfully deleted', 'success');
      this.$analytics.logEvent('poker-delete', { session_id: session.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
